import { storeToRefs } from "pinia"
import { useAuthStore } from "~/store/auth"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const config = useRuntimeConfig()
    const { user } = storeToRefs(useAuthStore()) // make authenticated state reactive
    const cookie = useCookie(config.public.authCookieName) // get token from cookies

    // check if value exists
    if (cookie.value) {

        const cookieValue = cookie.value as unknown as {
            id: string;
            token: string;
            displayName: string;
            email: string;
            company: string;
            verificationCode: string;
        };

        user.value = {
            id: cookieValue.id,
            token: cookieValue.token,
            displayName: cookieValue.displayName,
            email: cookieValue.email,
            company: cookieValue.company,
            verificationCode: cookieValue.verificationCode
        }
    }
    
    // if token exists and url is sign in or sign up, redirect to homepage
    if (cookie.value && (to?.name === 'auth-signin' || to?.name === 'auth-signup')) {
        return navigateTo('/')
    }

    // if token doesn't exist and url is not sign in or sign up, redirect to log in
    if (!cookie.value && to?.name !== 'auth-signin' && to?.name !== 'auth-signup') {
        abortNavigation()
        return navigateTo('/auth/signin')
    }
})